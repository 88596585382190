.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex_col {
  flex-direction: column;
}

.flex {
  display: flex;
  /*background-color: rgb(227, 227, 227);*/
}

.full_height {
  height: 100dvh;
}

.hundred {
  height: 100%;
}

.full_width {
  width: 100%;
}

.fit_content {
  width: fit-content;
}

.w2\/3 {
  width: 66.6666666667%;
}

.h2\/3 {
  height: 66.6666666667%;
}

.margin_auto {
  margin-left: auto;
  margin-right: auto;
}

.Main_Content {
  flex-grow: 100;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

#main_content {
  overflow-y: hidden;
  margin-bottom: 30px;
  scrollbar-gutter: stable;
  --sb-track-color: #cecece;
  --sb-thumb-color: #abafab;
  --sb-size: 8px;
}

#main_content:hover {
  overflow-y: auto;
}

.Left_Menu {
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 260px;
  max-width: 260px;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f9f9f9;
}

.chatbox {
  line-height: 1.2;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  resize: none;
  text-align: left;
}

.chatbox:focus {
  outline: none;
}

.user_chat {
  background-color: rgba(244,244,244,1);
  white-space: pre-wrap;
  margin-left: auto;
  margin-right: 0;
}

.chat_content {
  border-radius: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: left;
}

.submit_form_div {
  border-radius: 1.5rem;
  background-color: #f0efef;
}

.msg_image_left {
  text-align: left;
}

.msg_image_div {
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 0px;
  height: 50px;
}

.msg_image {
  max-width: 50px;
  max-height: 50px;
}

.img_del_div {
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 16px;
  padding-top: 10px;
}

.img_del {
  width: 16px;
  height: 16px;
}

.submit_form {
  align-items: center;
  width: 48rem;
}

.submit_form > * {
  border: none;
  background: none;
}

.bot {
  padding-left: 1.25rem;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;
}

.new_chat, .new_chat:visited, .new_chat:hover, .new_chat:active{
  color: inherit;
}

.username {
  text-align: right;
  font-weight: bold;
  padding-top: 10px;
}

.content_menu {
  overflow-y: hidden;
  scrollbar-gutter: stable;
  --sb-track-color: #cecece;
  --sb-thumb-color: #abafab;
  --sb-size: 8px;
}

.content_menu:hover {
  overflow-y: auto;
}

.menu_item {
  text-align: left;
  border-radius: 0.75rem;
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.menu_item:hover {
  background-color: #ececec;
  cursor: pointer;
}

.menu_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  font-size: medium;
  padding: 0.5rem;
}

.menu_delete_button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.menu_item:hover .menu_delete_button {
  display: block;
}

.account {
  padding-top: 15px;
}

.blinking {
  animation: blinking 1s infinite;
  font-size: large;
  font: bold;
}

.katex-mathml {
  display: none;
}

.katex-html {
  white-space: pre-wrap;
}

@keyframes blinking {
	0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content_menu::-webkit-scrollbar {
  width: var(--sb-size)
}

.content_menu::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 12px;
}

.content_menu::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 12px;
}

#main_content::-webkit-scrollbar {
  width: var(--sb-size)
}

#main_content::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 12px;
}

#main_content::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 12px;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}